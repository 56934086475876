import { render, staticRenderFns } from "./ContactPageContent.vue?vue&type=template&id=57397543&scoped=true"
import script from "./ContactPageContent.vue?vue&type=script&lang=ts"
export * from "./ContactPageContent.vue?vue&type=script&lang=ts"
import style0 from "./ContactPageContent.vue?vue&type=style&index=0&id=57397543&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57397543",
  null
  
)

export default component.exports