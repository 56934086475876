
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class TextInputWrapper extends Vue {

  @Prop()
  public value!: string;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: '' })
  public suffix!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: '' })
  public label!: string;

  @Prop({ default: '' })
  public hint!: string;

  @Emit()
  public input(option: string) {
    return option;
  }

  @Emit()
  public enter(): void {
  }

  @Emit()
  public blur(): void {
  }

  @Emit()
  public esc(): void {
  }

  public get allRules(): any[] {
    if (this.required) {
      return [
        (text: string) => {
          if (!text || !text.trim()) {
            return 'Required';
          } else {
            return true;
          }
        }
      ];
    } else {
      return [];
    }
  }
}

