
import { AllLabels } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import ContactPageContent from './ContactPageContent.vue';


@Component({
  components: {
    Footer,
    ContactPageContent
  }
})
export default class ContactPage extends Vue {

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
}

