
import { AllLabels } from '@/interfaces';
import { labelsStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/ui/AppButton.vue';
import TextInputWrapper from '@/components/ui/TextInputWrapper.vue';

@Component({
  components: {
    AppButton,
    TextInputWrapper,
  }
})
export default class ContactPageContent extends Vue {

  public displayNameValue: string = '';
  public displayCompanyValue: string = '';
  public displayEmailValue: string = '';
  public displayMessageValue: string = '';

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public onSendClicked(): void {

  }

  public onDisplayNameChanged(displayNameValue: string): void {
    this.displayNameValue = displayNameValue;
    // casesGraphStore.setWhiskerMaxDisplayHours(+this.displayMaxHoursValue);
  }

  public onDisplayCompanyChanged(displayCompanyValue: string): void {
    this.displayCompanyValue = displayCompanyValue;
    // casesGraphStore.setWhiskerMaxDisplayHours(+this.displayMaxHoursValue);
  }

  public onDisplayEmailChanged(displayEmailValue: string): void {
    this.displayEmailValue = displayEmailValue;
    // casesGraphStore.setWhiskerMaxDisplayHours(+this.displayMaxHoursValue);
  }

  public onDisplayMessageChanged(displayMessageValue: string): void {
    this.displayMessageValue = displayMessageValue;
    // casesGraphStore.setWhiskerMaxDisplayHours(+this.displayMaxHoursValue);
  }
}

